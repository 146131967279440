import {Link} from '@ezcater/tapas';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import appsFlyerQrFooter from '@/assets/icons/apps-flyer-native-app-download-qr-footer.svg';
import appStore from '@/assets/images/app-store.svg';
import googlePlay from '@/assets/images/google-play.svg';
import useTracking from '@/hooks/useTracking';

const AppDownloadCta: React.FC<unknown> = () => {
  const {t} = useTranslation('common');
  const {trackClick} = useTracking();

  const onClick = (element: string) => {
    trackClick(`footer-app-download-${element}`);
  };

  return (
    <div className="mx-auto my-7 flex max-w-[682px] items-center justify-between gap-16 rounded-md border border-peppercorn-200 p-6 pr-4">
      <div className="flex flex-col items-start gap-2">
        <h4 className="text-xl font-bold text-peppercorn-800">
          {t('components.AppDownloadCta.title')}
        </h4>
        <p className="text-left text-peppercorn-800">
          {t('components.AppDownloadCta.description')}
        </p>
        <div className="flex gap-3 pt-2">
          <Link
            onClick={() => onClick('app-store')}
            href="https://ezcater.onelink.me/T4QA/uz08mhmh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={appStore} alt={t('components.AppDownloadCta.appStore')} />
          </Link>
          <Link
            onClick={() => onClick('google-play')}
            href="https://ezcater.onelink.me/T4QA/kungbqqa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={googlePlay} alt={t('components.AppDownloadCta.googlePlay')} />
          </Link>
        </div>
      </div>
      <Image alt="" src={appsFlyerQrFooter.src} width="136" height="136" />
    </div>
  );
};

export default AppDownloadCta;
