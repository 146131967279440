import {memo} from 'react';
import {EzContent} from '@ezcater/recipe';
import {Link} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';

import useEzCaterCompanyInfo from '@/hooks/useEzCaterCompanyInfo';
import {
  ABOUT_US_PATH,
  ACCESSIBILITY_PATH,
  BLOG_PATH,
  CA_NOTICE_OF_COLLECTION_PATH,
  CAREERS_PATH,
  CATERER_PARTNERS_PATH,
  COMPANY_NEWS_PATH,
  CONTACT_PATH,
  CORPORATE_SOLUTIONS_PATH,
  EZ_REWARDS_PATH,
  FAQ_PATH,
  PRIVACY_PATH,
  TERMS_PATH,
} from '@/paths';
import EzOExperience from '../EzOExperience';
import MarketplaceExperience from '../MarketplaceExperience';
import AppDownloadCta from './AppDownloadCta';
import AppDownloadCtaMobile from './AppDownloadCtaMobile';
import Disclosure from './Disclosure';
import Divider from './Divider';
import SocialLinks from './SocialLinks';
import TrackedLinks from './TrackedLinks';
import TranscendButton from './TranscendButton';

const navLinks = [
  {key: 'aboutUs', path: ABOUT_US_PATH},
  {key: 'careers', path: CAREERS_PATH},
  {key: 'blog', path: BLOG_PATH},
  {key: 'contactUs', path: CONTACT_PATH},
  {key: 'faq', path: FAQ_PATH},
  {key: 'becomeACaterer', path: CATERER_PARTNERS_PATH},
  {key: 'corporateSolutions', path: CORPORATE_SOLUTIONS_PATH},
];

const mutedLinks = [
  {key: 'privacy', path: PRIVACY_PATH},
  {key: 'terms', path: TERMS_PATH},
  {key: 'caNoticeAtCollection', path: CA_NOTICE_OF_COLLECTION_PATH},
  {key: 'accessibility', path: ACCESSIBILITY_PATH},
];

const ezoMutedLinks = [
  {key: 'privacy', path: PRIVACY_PATH},
  {key: 'terms', path: TERMS_PATH},
  {key: 'accessibility', path: ACCESSIBILITY_PATH},
];

const companyLinks = [
  {key: 'aboutUs', path: ABOUT_US_PATH},
  {key: 'newsAndEvents', path: COMPANY_NEWS_PATH},
  {key: 'blog', path: BLOG_PATH},
  {key: 'contactUs', path: CONTACT_PATH},
  {key: 'faq', path: FAQ_PATH},
  {key: 'careers', path: CAREERS_PATH},
  {key: 'terms', path: TERMS_PATH},
  {key: 'accessibility', path: ACCESSIBILITY_PATH},
  {key: 'privacy', path: PRIVACY_PATH},
];

const marketplaceMobileLinks = [
  {key: 'caNoticeAtCollection', path: CA_NOTICE_OF_COLLECTION_PATH},
  {key: 'doNotSellMyInfo', path: 'transcend-modal'},
];

const discoverLinks = [
  {key: 'becomeACaterer', path: CATERER_PARTNERS_PATH},
  {key: 'corporateSolutions', path: CORPORATE_SOLUTIONS_PATH},
  {key: 'rewards', path: EZ_REWARDS_PATH},
];

const Copyright = () => <>&copy; {new Date().getFullYear()} ezCater, Inc.</>;

type MarketplaceFooterProps = {
  orderNumber?: number | null;
  customBrandText?: string;
};

const MarketplaceFooter = ({orderNumber, customBrandText}: MarketplaceFooterProps) => {
  const {t} = useTranslation('common');
  const companyInfo = useEzCaterCompanyInfo();
  const supportPhoneNumber = companyInfo?.supportPhoneNumber;

  return (
    <>
      <footer
        aria-label="App Footer"
        className="hidden w-full border-t border-gray-300 p-8 text-center text-sm font-normal desktop:block"
      >
        <MarketplaceExperience>
          <ul className="mx-0 mt-3 flex list-none flex-wrap justify-center p-0 [&_a]:whitespace-nowrap [&_a]:px-2 [&_a]:pt-0 [&_a]:font-normal">
            <TrackedLinks links={navLinks} className="text-blueberry-400" />
          </ul>
          <AppDownloadCta />
        </MarketplaceExperience>
        <EzOExperience>{customBrandText && <p>{customBrandText}</p>}</EzOExperience>
        <ul className="mx-0 mt-3 flex list-none flex-wrap justify-center p-0 text-peppercorn-800 [&_a]:font-normal [&_a]:text-gray-400">
          <EzOExperience>
            <>
              <li>{t('components.Footer.ezOrderingPoweredBy')}</li>
              <Divider />
            </>
          </EzOExperience>
          <li>
            <Copyright />
          </li>
          <Divider />
          <EzOExperience>
            <TrackedLinks links={ezoMutedLinks} useDivider />
            <TranscendButton as="li" className="text-gray-400" />
          </EzOExperience>
          <MarketplaceExperience>
            <TrackedLinks links={mutedLinks} useDivider className="!text-peppercorn-800" />
            <TranscendButton as="li" className="text-peppercorn-800" />
          </MarketplaceExperience>
        </ul>
        <MarketplaceExperience>
          <SocialLinks />
        </MarketplaceExperience>
      </footer>
      <footer
        aria-label="App Footer"
        className="block w-full border-t border-gray-300 bg-gray-100 p-8 text-center text-sm font-normal desktop:hidden"
      >
        <MarketplaceExperience>
          <AppDownloadCtaMobile />
          <div className="rounded-md bg-white py-5">
            <EzContent>
              <div className="my-0 font-black uppercase tracking-wide">
                {t('components.Footer.supportBox.question')}
              </div>
              {supportPhoneNumber && (
                <Link
                  href={`tel:${supportPhoneNumber}`}
                  title={t('components.Footer.supportBox.supportPhoneTitle')}
                  size="medium"
                >
                  {supportPhoneNumber}
                </Link>
              )}
              {orderNumber && (
                <div>{t('components.Footer.supportBox.referenceNumber', {orderNumber})}</div>
              )}
            </EzContent>
          </div>
          <Disclosure label="Company">
            <ul className="m-0 flex list-none flex-col gap-2 pt-4 text-left text-base [&_a:hover]:text-blue-600 [&_a]:text-gray-400">
              <TrackedLinks links={companyLinks} />
              <MarketplaceExperience>
                <TrackedLinks links={marketplaceMobileLinks} />
              </MarketplaceExperience>
            </ul>
          </Disclosure>
          <Disclosure label="Discover">
            <ul className="m-0 flex list-none flex-col gap-2 pt-4 text-left text-base [&_a:hover]:text-blue-600 [&_a]:text-gray-400">
              <TrackedLinks links={discoverLinks} />
            </ul>
          </Disclosure>
        </MarketplaceExperience>
        <EzOExperience>
          {customBrandText && <p>{customBrandText}</p>}
          <ul className="mx-0 mt-3 flex list-none flex-wrap justify-center p-0 text-gray-400 [&_a]:font-normal [&_a]:text-gray-400 [&_a]:underline">
            <>
              <li>{t('components.Footer.ezOrderingPoweredBy')}</li>
              <Divider />
            </>
            <li>
              <Copyright />
            </li>
            <Divider />
            <TrackedLinks links={ezoMutedLinks} useDivider />
          </ul>
        </EzOExperience>
        <MarketplaceExperience>
          <SocialLinks />
          <div className="mt-3">
            <Copyright />
          </div>
        </MarketplaceExperience>
      </footer>
    </>
  );
};

export default memo(MarketplaceFooter);
